<template>
  <div class="login-signin">
    <div class="mb-0">
      <h3 class="font-weight-normal text-dark">Reset You Password</h3>
      <!--      <p class="opacity-40">Enter your details to login to your account:</p>-->
    </div>
    <b-form class="form" id="kt_login_signin_form" @submit.stop.prevent="onSubmit">
      <div
          v-if="errors.length > 0"
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error.message }}
        </div>
      </div>
        <b-form-input
            class="mb-3 form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8 login-input"
            type="password"
            id="example-input-1"
            name="example-input-1"
            placeholder="New Password"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-1-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-1-live-feedback" class="mb-3 error-div">
          Password is required. (Minimum 6 and maximum 10 characters)
        </b-form-invalid-feedback>

        <b-form-input
            class="mb-3 form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8 login-input"
            type="password"
            id="example-input-2"
            name="example-input-2"
            placeholder="Confirm New Password"
            v-model="$v.form.confirm_password.$model"
            :state="validateState('confirm_password')"
            aria-describedby="input-2-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="input-2-live-feedback" class="mb-3 error-div">
          Confirm your new password, must be same as above.
        </b-form-invalid-feedback>
      <div class="form-group text-center mt-5">
        <button ref="kt_login_signin_submit" class="btn btn-pill btn-primary opacity-90 px-15 py-3">Reset Password</button>
      </div>
    </b-form>
    <!--	<div class="mt-10">
        <span class="opacity-40 mr-4">Don't have an account yet?</span>
        <a href="javascript:;" id="kt_login_signup" class="text-white opacity-30 font-weight-normal">Sign Up</a>
      </div> -->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
.login-input {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.login-input::placeholder {
  color: #000000;
  opacity: 1; /* Firefox */
}

.login-input::-ms-input-placeholder { /* Edge 12 -18 */
  color: #000000;
}

.error-div {
  color: #ffffff;
  background-color: #F64E60;
  padding: 5px 5px;
  margin-top: 5px;
  border-radius: 9999px;
  text-align: center;
}
</style>

<script>
import {mapState} from "vuex";
import {RESET_PASSWORD, CLEAR_AUTH_ERRORS} from "@/core/services/store/auth.module";

import {validationMixin} from "vuelidate";
import {minLength, required, sameAs, maxLength} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        password: "",
        confirm_password: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(10)
      },
      confirm_password: {
        required,
        sameAs: sameAs( function(){return this.form.password} )
      }
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        password: null,
        confirm_password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const password = this.$v.form.password.$model;
      const password_confirmation = this.$v.form.confirm_password.$model;
      const token = this.$route.params.id

      // clear existing errors
      this.$store.dispatch(CLEAR_AUTH_ERRORS);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      // send login request
      this.$store
          .dispatch(RESET_PASSWORD, {password, password_confirmation, token})
          // go to which page after successfully login
          .then((data) => {
            if (data._metadata.outcomeCode === 0) {
              Swal.fire('', data._metadata.message, 'success')
              submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
              );
              this.$router.push("/login");
              submitButton.disabled = false;
            } else {
              submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
              );
              submitButton.disabled = false;
            }
          });
    },
    getLogo() {
      return process.env.BASE_URL + "media/logos/logo.jpg";
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
